<template>
    <div id="appCapsule">
        <div class="section wallet-card-section">
            <div class="card">
                <div class="col-12 ps-2 text-primary py-2">
                    Support Messages
                </div>
                <div class="table-responsive">
                    <table class="table">
                        <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Ref.</th>
                            <th scope="col">Unread</th>
                            <th scope="col" class="text-end">Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                        <template v-if="supportMsgs.length > 0">
                            <tr v-for="(msg, idx) in supportMsgs" :key="idx">
                                <th scope="row">{{idx+1}}</th>
                                <td>
                                    <template v-if="users[idx] !== undefined">
                                        {{ users[idx].data.name }} ({{users[idx].data.email}})
                                    </template>
                                    <template v-else>
                                        {{msg.id.substr(0,10)}}
                                    </template>
                                </td>
                                <td>{{msg.data.unread}}</td>
                                <td>
                                    <div class="row justify-content-around">
                                        <router-link :to="{name:'UserSupportMessages', params: {userId: msg.id}}" class="text-success text-center">
                                            <ion-icon name="chatbubble-ellipses-outline" class="fw-20"></ion-icon>
                                        </router-link>
                                    </div>
                                </td>
                            </tr>
                        </template>
                        <template v-else>
                            <tr>
                                <td class="text-center text-primary" colspan="4">
                                    <h5>Empty Support Messages</h5>
                                </td>
                            </tr>
                        </template>
                        </tbody>
                    </table>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
    name: "supportMessages",
    data(){
        return {
            active_payload: {id: '', data: {}},
            users: []
        }
    },
    computed: {
        ...mapGetters('support', {
            supportMsgs: 'getAllMessages'
        })
    },
    watch: {
        supportMsgs(){
            this.fetchUserMeta();
        }
    },
    methods: {
        async fetchUserMeta(){
            const userPromises = this.supportMsgs.map(msg=>this.$store.dispatch('user/getUser', msg.id));
            this.users = (await Promise.all(userPromises)).map(response=>response.data);
        },
    },
    mounted() {
        this.$store.dispatch('support/fetchAllSupportMessages')
    }
}
</script>

<style scoped>

</style>